import React, { useRef, useEffect } from 'react'
import styles from './Page.module.scss'

type Props = {
  title?: string,
  full?: boolean,
  children: React.Node,
}

const Page = ({ title, full, children }: Props) => {
  const pageRef = useRef()

  useEffect(() => {
    pageRef.current.scrollIntoView()
  })

  const pageClass = full
    ? `${styles['page']} ${styles['full']}`
    : styles['page']

  return (
    <div ref={pageRef} className={pageClass}>
      <div className={styles['page__inner']}>
        {title && <h1 className={styles['page__title']}>{title}</h1>}
        <div className={styles['page__body']}>{children}</div>
      </div>
    </div>
  )
}

export default Page
